import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { iClientInfo, ISurveyAnswer, ISurveyResponse } from "../model/search";

const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_AMPLIFIQUE,
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);

  instance.interceptors.request.use(async (configInstance) => {
    const token = process.env.REACT_APP_TOKEN_AMPLIFIQUE;
    if (token != null) {
      configInstance.headers!.Authorization = `Bearer ${token}`;
    }
    return configInstance;
  });

  return instance;
};

export const api = createApiInstance();

export const amplifiqueApi = () => {
  const getSearchByClient = (
    clientInfo: iClientInfo
  ): Promise<AxiosResponse<ISurveyResponse>> => {
    return api.post<ISurveyResponse>("", clientInfo);
  };

  const sendSurveyResponse = (
    surveyAnswer: ISurveyAnswer,
    id: string,
    isFinalize: boolean
  ): Promise<AxiosResponse<ISurveyResponse>> => {
    return api.put<ISurveyResponse>(`${id}?isFinalize=true`, surveyAnswer);
  };

  return {
    getSearchByClient,
    sendSurveyResponse,
  };
};

export default amplifiqueApi();
