import { useCallback, useEffect, useState } from "react";
import logo from "./assets/images/frisia-white-logo.svg";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faFaceGrin,
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons";
import amplifiqueService from "./services/amplifique-service";
import QuestionRow from "./components/QuestionRow";
import { Answer, Question } from "./model/search";
import maximize from "./assets/images/maximize-solid.svg";
import Modal from "@mui/material/Modal";

function App() {
  const [ratingNumber, setRatingNumber] = useState<number>();
  const [surveyAnswerId, setSurveyAnswerId] = useState<string>();
  const [answersArray, setAnswersArray] = useState<Answer[]>([]);
  const [questionsArray, setQuestionsArray] = useState<Question[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [greatMessage, setGreatMessage] = useState<string>(
    "Obrigado pela resposta! Ficamos felizes em saber que você foi bem atendido(a)."
  );
  const [goodMessage, setGoodMessage] = useState<string>(
    "Obrigado pela resposta! Ficamos felizes em saber que você foi bem atendido(a)."
  );
  const [badMessage, setBadMessage] = useState<string>(
    "Sentimos pela falha! Sua avaliação ajudará nas providências necessárias em nosso atendimento"
  );
  const [inFullscreen, setInFullscreen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    eraseAnswers();
  };

  const eraseAnswers = () => {
    setRatingNumber(undefined);
    setAnswersArray([]);
  };

  const getData = () => {
    amplifiqueService
      .getSearchByClient({
        name: "Customer Name",
        email: "customer@email.com",
        company: "Customer Company",
        phone: "+55DD9XXXXXXXX",
      })
      .then((response) => {
        setSurveyAnswerId(response.surveyAnswer.id);
        setQuestionsArray(response.survey.questions);
        response.survey.thankyou_page.variations.forEach((item) => {
          if (item.condition === "CSAT_PDV,entre,1,2") {
            setBadMessage(item.message);
          } else if (item.condition === "CSAT_PDV,igual,3") {
            setGoodMessage(item.message);
          } else {
            setGreatMessage(item.message);
          }
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function requestFullScreen() {
    let elem = document.body;

    if (!document.fullscreenElement) {
      setInFullscreen(!inFullscreen);
      elem.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
      setInFullscreen(!inFullscreen);
    }
  }

  const addFacesResponse = (buttonNumber: number) => {
    const newAnswer = {
      type: "csat",
      internal_name: "CSAT_PDV",
      answer: buttonNumber,
      isMainMetric: true,
    };

    setAnswersArray((oldAnswersArray) => [...oldAnswersArray, newAnswer]);
  };

  const sendAnswer = (answersArray: Answer[]) => {
    if (surveyAnswerId) {
      amplifiqueService
        .sendSurveyResponse(
          {
            answers: answersArray,
          },
          surveyAnswerId,
          false
        )
        .then((response) => {
          handleOpen();
          setTimeout(() => {
            eraseAnswers();
            handleClose();
          }, 5000);
        });
    }
  };

  const renderReportOnScreen = useCallback(() => {
    if (!!ratingNumber) {
      return (
        <div className="avaliate-container">
          <h1 className="h1-header">Avalie os pontos abaixo</h1>
          <div className="all-question-container">
            {questionsArray
              .filter((item) => item.internal_name !== "CSAT_PDV")
              .map((item, index) => (
                <QuestionRow
                  key={index}
                  setAnswersArray={setAnswersArray}
                  question={item}
                />
              ))}
          </div>
          <div
            style={{
              display: "flex",
              margin: "30px",
              marginTop: "0px",
            }}
          >
            <button
              className="action-button"
              onClick={() => {
                setRatingNumber(undefined);
                setAnswersArray([]);
              }}
            >
              Voltar
            </button>
            <button
              style={{ margin: "auto" }}
              className="action-button"
              onClick={() => sendAnswer(answersArray)}
            >
              Finalizar
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="buttons-container">
          <button
            onClick={() =>
              sendAnswer([
                {
                  type: "csat",
                  internal_name: "CSAT_PDV",
                  answer: 5,
                  isMainMetric: true,
                },
              ])
            }
            className="card-body col-sm-4 card styled-button green"
          >
            <FontAwesomeIcon icon={faFaceGrin} />
            <span>Muito Satisfeito</span>
          </button>
          <button
            onClick={() => {
              setRatingNumber(3);
              addFacesResponse(3);
            }}
            className="card-body col-sm-4 card styled-button yellow"
          >
            <FontAwesomeIcon icon={faFaceSmile} />
            <span>Satisfeito</span>
          </button>
          <button
            onClick={() => {
              setRatingNumber(1);
              addFacesResponse(1);
            }}
            className="card-body col-sm-4 card styled-button red"
          >
            <FontAwesomeIcon icon={faFaceFrown} />
            <span>Insatisfeito</span>
          </button>
        </div>
      );
    }
  }, [ratingNumber, answersArray, surveyAnswerId]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <img
            src={logo}
            className="img-logo"
            alt="Imagem do logo da Frisia branco"
          />
        </div>
        {renderReportOnScreen()}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <div className="modal" onClick={handleClose}>
            {ratingNumber && ratingNumber < 3 ? (
              <span>{badMessage}</span>
            ) : ratingNumber && ratingNumber > 3 ? (
              <span>{greatMessage}</span>
            ) : (
              <span>{goodMessage}</span>
            )}
          </div>
        </Modal>
        <div
          onClick={() => {
            requestFullScreen();
          }}
          style={{
            height: "9vh",
            width: "9vw",
            position: "absolute",
            bottom: "0",
            right: "0",
            margin: "20px",
          }}
        >
          {!inFullscreen && (
            <img
              style={{
                height: "9vh",
                width: "9vw",
              }}
              src={maximize}
              alt="like"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
